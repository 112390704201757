import React, { useContext } from "react";
import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import currency from "currency.js";
import ConfigContext from "../context/ConfigContext";

export default function LineItemSelection(props) {
  const { item } = props;
  const siteConfig = useContext(ConfigContext);

  return (
    <Box>
      {item?.options && (
        <Box mt={2} p={2} sx={{ border: "1px solid #ECECEC" }}>
          <Box mb={4}>
            <Typography variant="body1" gutterBottom>
              Select your Options
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Tip: you can change your selection even after you place your order. Simply let us know a week before your order due date.
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {item.options?.flavors && (
              <Grid item sm={12} xs={12}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="flavor-label">{`Flavor`}</InputLabel>
                  <Select
                    required
                    variant="outlined"
                    value={item.variant?.flavor?.base || ""}
                    renderValue={(option) => {
                      // check for surcharge
                      const tmp = item.options.flavors.filter((o) => o.name === option.name);
                      const surcharge = (tmp && tmp.length > 0 && tmp[0].fee) || "";
                      return `${option.name} ${surcharge && ` • +${currency(surcharge).format()}`}`;
                    }}
                    labelId="flavor-label"
                    label="Flavor"
                    id="flavor"
                    name="flavor"
                    onChange={(e) => {
                      let newValue = e.target.value;
                      // change format
                      let o = {};
                      if (item.variant?.flavor) {
                        if (Array.isArray(item.variant?.flavor)) {
                          let firstEl = item.variant.flavor[0];
                          if (firstEl.name) {
                            o.base = { name: firstEl.name };
                          }
                          if (firstEl.filling) {
                            o.filling = { name: firstEl.filling };
                          }
                        } else {
                          o = item.variant?.flavor;
                        }
                      }
                      o.base = { name: newValue.name };
                      if (newValue.fee && +newValue.fee > 0) {
                        o.base.pricing = { amount: newValue.fee };
                      }
                      props.onChange({ ...item, variant: { ...item.variant, flavor: o } });
                    }}
                  >
                    {item.options?.flavors
                      .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                      .map((option, idx) => (
                        <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                          <Box>
                            <Typography variant="body1">
                              {option.name} {+option.fee > 0 ? ` ● +${currency(option.fee).format()}` : ""}{" "}
                              {option.default && <Chip size="small" label={`bestseller`} />}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {option.desc}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {item.options?.fillings && (
              <Grid item sm={12} xs={12}>
                {Object.values(item.options.fillings).length > 0 && (
                  <FormControl variant="outlined" fullWidth required={siteConfig.id !== "IpDJo6abalT5OKbkFX6C" && siteConfig.id !== "5yGl3okejphd9VyjiMQk"}>
                    <InputLabel id="filling-label">{`Filling`}</InputLabel>
                    <Select
                      variant="outlined"
                      value={item.variant?.flavor?.filling || ""}
                      renderValue={(option) => {
                        // check for surcharge
                        const tmp = item.options.fillings.filter((o) => o.name === option.name);
                        const surcharge = (tmp && tmp.length > 0 && tmp[0].fee) || "";
                        return `${option.name} ${surcharge && ` • +${currency(surcharge).format()}`}`;
                      }}
                      labelId="filling-label"
                      label="Filling"
                      id="filling"
                      name="filling"
                      onChange={(e) => {
                        let newValue = e.target.value;
                        // change format
                        let o = {};
                        if (item.variant?.flavor) {
                          if (Array.isArray(item.variant?.flavor)) {
                            let firstEl = item.variant.flavor[0];
                            if (firstEl.name) {
                              o.base = { name: firstEl.name };
                            }
                            if (firstEl.filling) {
                              o.filling = { name: firstEl.filling };
                            }
                          } else {
                            o = item.variant?.flavor;
                          }
                        }
                        o.filling = { name: newValue.name };
                        if (newValue.fee && +newValue.fee > 0) {
                          o.filling.pricing = { amount: newValue.fee };
                        }
                        props.onChange({ ...item, variant: { ...item.variant, flavor: o } });
                      }}
                    >
                      {item.options.fillings
                        .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                        .map((option, idx) => (
                          <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                            <Box>
                              <Typography variant="body1">
                                {option.name} {+option.fee > 0 ? ` ● +${currency(option.fee).format()}` : ""}{" "}
                                {option.default && <Chip size="small" label={`bestseller`} />}
                              </Typography>
                              <Typography variant="caption" color="textSecondary">
                                {option.desc}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            )}
            {item?.options?.inscription && (
              <Grid item sm={12} xs={12}>
                <TextField
                  id="inscription"
                  value={item.variant?.customization?.inscription?.text}
                  label="Inscription (optional)"
                  name="inscription"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    let o = item.variant.customization || {};
                    o.inscription = { text: e.target.value };

                    props.onChange({ ...item, customization: o });
                  }}
                  helperText={`Add a greeting like "Happy Birthday", a name like "Alice" or a number like "4"`}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
